<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="800"
      :persistent="load"
    >
      <v-card
        flat
        :disabled="load"
        :loading="load"
      >
        <v-card-title>
          <v-toolbar
            flat
            class="text-center"
          >
            <v-icon left>far fa-chart-bar</v-icon> Grupos para {{ nombre }}
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="activo = false"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers"
                :items="detalles"
                :loading="load"
                dense
                :search="search"
              >
                <!-- Sin datos -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin Grupos para mostrar.
                  </v-alert>
                </template>
                <!-- Porcentaje -->
                <template v-slot:[`item.porcentaje`]="{ item }">
                  {{ item.porcentaje }}%
                </template>
                <!-- Fecha Desde -->
                <template v-slot:[`item.fecha_desde`]="{ item }">
                  {{ format_date(item.fecha_desde) }}
                </template>
                <!-- Fecha Hasta -->
                <template v-slot:[`item.fecha_hasta`]="{ item }">
                  {{ format_date(item.fecha_hasta) }}
                </template>
                <!-- Grupo -->
                <template v-slot:[`item.grupo_nombre`]="{ item }">
                  {{ item.grupo_nombre }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { order_list_by_date2, format_date } from '../../util/utils'
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearVerGpoFP', value)
      }
    },
    fp_cond_id: {
      get(){
        return this.datos.fp_cond_id
      }
    },
    nombre: {
      get(){
        return this.datos.nombre
      }
    }
  },
  data() {
    return {
      format_date: format_date,
      headers: [
        { text: 'F. Desde', align: 'center', value: 'fecha_desde' },
        { text: 'F. Hasta', align: 'center', value: 'fecha_hasta' },
        { text: 'Grupo', align: 'center', value: 'grupo_nombre' },
        { text: 'Porcentaje', align: 'center', value: 'porcentaje' }
      ],
      detalles: [],
      load: false,
      search: ''
    }
  },
  methods: {
    async initForm(){
      this.load = true
      let initPeticion = await this.$store.dispatch('financiaciones/get_periodos_grupo_fp', {fp_cond_id: this.fp_cond_id})
      this.load = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error'
        })
        return
      }
      // ordeno descendiente
      order_list_by_date2(initPeticion.grupos, 'fecha_desde', 'asc')
      this.detalles = initPeticion.grupos
    },
    clearForm(){
      this.detalles = []
      this.search = ''
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.clearForm()
      else this.initForm()
    }
  },
}
</script>

<style>

</style>